var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "textinput flex-field", style: _vm.style }, [
    _vm.label
      ? _c("label", { staticClass: "label is-size-7" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
          _vm.isRequired
            ? _c(
                "span",
                {
                  staticClass: "has-text-warning",
                  staticStyle: { "padding-left": "3px" },
                },
                [_vm._v("*")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "field" }, [
      _c("input", {
        staticClass: "textfield",
        attrs: { type: "text" },
        domProps: { value: _vm.stringVal },
      }),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          attrs: { multiple: "" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.dataItems, function (item) {
          return _c("option", { key: item, domProps: { value: item } }, [
            _vm._v(_vm._s(item.text)),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }