var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageheading } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c(
              "div",
              {
                staticClass: "annual-questionnaire-form",
                attrs: { id: "body-content-area" },
              },
              [
                _c("div", [
                  _c(
                    "form",
                    {
                      staticStyle: { margin: "0 auto" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.validateBeforeSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.isViewOnly
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-bottom": "20px",
                                width: "100%",
                                display: "flex",
                                "justify-content": "flex-end !important",
                                gap: "0.5rem",
                              },
                              attrs: { id: "buttongroup" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "60px" } },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button is-accent has-text-white",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["ANNUAL_QUESTIONNAIRE"],
                                          2
                                        ),
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ]
                              ),
                              _c("div", {}, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-light",
                                    on: {
                                      click: function ($event) {
                                        _vm.resetForm()
                                        _vm.$router.go(-1)
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isViewOnly
                        ? _c("div", { staticClass: "columns" }, [
                            _c(
                              "div",
                              {
                                staticClass: "column",
                                staticStyle: { "max-width": "50%" },
                              },
                              [
                                _vm._v(" Please answer all questions: "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "block",
                                    staticStyle: { "margin-top": "3rem" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-container",
                                        staticStyle: { gap: "1rem" },
                                      },
                                      [
                                        _c("TextInput", {
                                          staticStyle: { width: "320px" },
                                          attrs: {
                                            label: "Name",
                                            isRequired: true,
                                          },
                                          model: {
                                            value: _vm.name,
                                            callback: function ($$v) {
                                              _vm.name = $$v
                                            },
                                            expression: "name",
                                          },
                                        }),
                                        _c("WindowSelect", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            dataItems: _vm.changeOptions,
                                            label:
                                              "Evaluation of the material changes to BOLI Risk Management Policies",
                                            isRequired: true,
                                          },
                                          on: {
                                            change: function (e) {
                                              return _vm.updateChanges(e)
                                            },
                                          },
                                          model: {
                                            value: _vm.changes,
                                            callback: function ($$v) {
                                              _vm.changes = $$v
                                            },
                                            expression: "changes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }